import React, { FC, useCallback, useMemo, useState } from 'react';
import i18next from 'i18next';
import { observer } from 'mobx-react';
import { useQuery } from '@apollo/client';
import { DEFAULT_ALERT_FILTER_CATEGORY_TECHNICAL_LIST } from 'core/constants';
import { DataTablePageParams } from 'primereact/datatable';
import {
  GqlAnomalyAlertsPaginated,
  GqlPlotAlertsPaginated,
  GqlPlotSensorsSystems,
} from 'gql/types/plots';
import {
  GET_PLOTS_ALERTS_PAGINATED,
  GET_PLOTS_ANOMALY_ALERTS_PAGINATED,
  GET_PLOTS_SENSORS_SYSTEMS,
} from 'gql/queries/plots';
import { AlertSeverity, AlertState, AlertVariant } from 'models/alert';
import { useStores } from 'stores/hooks/hooks';
import { GrowerType } from 'models/grower';
import { subDays } from 'date-fns';
import { TABLE_ALERTS_MAX_ROWS_NUMBER } from '../AlertsTable.utils';
import { useAlertsTableFilters } from '../hooks/useAlertsTableFilters';
import AnomalyAlertsTable from './AnomalyAlertsTable';

const defaultLazyParams: DataTablePageParams = {
  first: 0,
  page: 0,
  rows: TABLE_ALERTS_MAX_ROWS_NUMBER,
};

const AnomalyAlertsTab: FC = observer(() => {
  const { resellersStore, snackBarStore } = useStores();
  const [lazyParams, setLazyParams] =
    useState<DataTablePageParams>(defaultLazyParams);

  const { alertFilters, debouncedFilters, onAlertFilterChange } =
    useAlertsTableFilters();

  const growerIds = resellersStore.selectedTreeNodeGrowerIds;
  const isGrower = resellersStore.selectedGrowerType === GrowerType.Grower;

  const querySeverity = isGrower
    ? [AlertSeverity.High, AlertSeverity.Medium, AlertSeverity.Low]
    : [AlertSeverity.High];

  const fromDate = useMemo(() => {
    const currentDate = new Date();
    const oneWeekBefore = subDays(currentDate, 7);
    return oneWeekBefore.toISOString();
  }, []);

  const { data: plotAnomalyAlerts, loading: isPlotAnomalyAlertsLoading } =
    useQuery<GqlAnomalyAlertsPaginated>(GET_PLOTS_ANOMALY_ALERTS_PAGINATED, {
      variables: {
        growerIds: [resellersStore.selectedGrowerId],
        alertPage: lazyParams.page,
        alertSize: lazyParams.rows,
        urgencies: querySeverity,
        subTree: true,
        from: fromDate, // ISOString format , example: '2024-01-01T00:00:00Z',
        ...debouncedFilters,
      },
      fetchPolicy: 'no-cache',
      skip: !growerIds.length,
      onError() {
        snackBarStore.showToast({
          detail: i18next.t('errors:something_went_wrong'),
        });
      },
    });

  const onPageChange = useCallback(
    (params: DataTablePageParams) => {
      if (params.page !== lazyParams.page) {
        setLazyParams(params);
      }
    },
    [lazyParams],
  );

  const anomalyAlertsData = useMemo(() => {
    if (!plotAnomalyAlerts) {
      return plotAnomalyAlerts;
    }

    plotAnomalyAlerts.anomalyDetection.content.forEach((alert) => {
      const alias = alert.sensorAlias || '';
      const depth = alert.sensorDepth || '';
      const sensorDetails =
        !!depth && !!alias ? `${depth},${alias}` : `${depth}${alias}`;

      alert.sensorDetails = sensorDetails;

      return alert;
    });

    return plotAnomalyAlerts;
  }, [plotAnomalyAlerts]);

  return (
    <AnomalyAlertsTable
      isReseller={!isGrower}
      alerts={anomalyAlertsData as GqlAnomalyAlertsPaginated}
      tableParams={lazyParams}
      loading={isPlotAnomalyAlertsLoading}
      onPageChange={onPageChange}
      alertFilters={alertFilters}
      onAlertFilterChange={onAlertFilterChange}
      variant={AlertVariant.Technical}
    />
  );
});

export default AnomalyAlertsTab;
