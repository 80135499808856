import { gql } from '@apollo/client';

export const GET_PLOTS_TECHNICAL_ALERTS = gql`
  query plots(
    $growerIds: [Int] = []
    $plotIds: [Int] = []
    $period: String
    $alertState: [String]
    $alertFilterCategory: [String]
    $severity: [String]
  ) {
    plots(filter: { growerIds: $growerIds, plotIds: $plotIds }) {
      id
      name
      location {
        latitude
        longitude
      }
      polygon {
        coordinates
      }
      alerts(
        alertFilter: {
          ignore: false
          alertState: $alertState
          alertFilterCategory: $alertFilterCategory
          period: $period
          severity: $severity
        }
      ) {
        id
        state
        subtype
        severity
        timestamp
        params {
          type
          label
          value
        }
        type {
          id
          iconCode
          filterCategory
          type
          description
        }
      }
    }
  }
`;

export const GET_PLOTS_AGRONOMIC_ALERTS = gql`
  query getPlots(
    $growerIds: [Int]
    $page: Int
    $size: Int
    $alertState: [String]
    $alertFilterCategory: [String]
    $severity: [String]
  ) {
    plots(filter: { growerIds: $growerIds, page: $page, size: $size }) {
      id
      name
      location {
        latitude
        longitude
      }
      polygon {
        coordinates
      }
      sensors {
        id
        sensorType {
          group
        }
        displayName
        longitude
        latitude
      }
      alerts(
        alertFilter: {
          ignore: false
          alertState: $alertState
          alertFilterCategory: $alertFilterCategory
          severity: $severity
        }
      ) {
        id
        state
        severity
        timestamp
        params {
          type
          label
          value
        }
        type {
          id
          iconCode
          filterCategory
          type
          description
        }
      }
    }
  }
`;

export const GET_PLOTS_ALERTS_PAGINATED = gql`
  query getPlots(
    $growerIds: [Int]
    $alertPage: Int
    $alertSize: Int
    $alertState: [String]
    $alertFilterCategory: [String]
    $alertTypeIds: [Int]
    $severity: [String]
    $alertType: String
    $alertSubType: String
    $plotName: String
    $from: Date
    $to: Date
  ) {
    pageAlerts(
      filter: { growerIds: $growerIds, plotName: $plotName }
      alertFilter: {
        ignore: false
        alertState: $alertState
        alertFilterCategory: $alertFilterCategory
        alertTypeIds: $alertTypeIds
        severity: $severity
        EAlertType: $alertType
        alertSubType: $alertSubType
        page: $alertPage
        size: $alertSize
        from: $from
        to: $to
      }
    ) {
      totalCount
      currentPage
      content {
        alertId
        severity
        plotId
        plotName
        growerName
        alertType
        alertSubType
        alertState
        alertSetTime
        alertClearTime
        alertIgnore
        alertParamsJson
        sensorId
        __typename
      }
      __typename
    }
  }
`;

export const GET_PLOTS_ANOMALY_ALERTS_PAGINATED = gql`
  query getAnomalyDetection(
    $growerIds: [Int]
    $alertPage: Int
    $alertSize: Int
    $from: Date
    $subTree: Boolean
    $urgencies: [String]
  ) {
    anomalyDetection(
      filter: { growerIds: $growerIds, subTree: $subTree }
      anomalyFilter: {
        page: $alertPage
        size: $alertSize
        from: $from
        urgencies: $urgencies
      }
    ) {
      totalCount
      currentPage
      content {
        growerId
        growerName
        fromTimestamp
        plotId
        sensorSerial
        anomalyType
        toTimestamp
        systemSerial
        timestampUtc
        valueMin
        valueMax
        location
        description
        alertCount
        plotName
        sensorName
        sensorType
        sensorDepth
        sensorAlias
        urgency
        __typename
      }
    }
  }
`;

export const GET_PLOTS_SENSORS_SYSTEMS = gql`
  query getPlots($growerIds: [Int], $page: Int, $size: Int) {
    plots(filter: { growerIds: $growerIds, page: $page, size: $size }) {
      sensors {
        id
        alias
        params
        sensorType {
          group
          family
          convertedUnits
          type
          __typename
        }
        serial
        systemId
        systemSerial
        displayName
        longitude
        latitude
        __typename
      }
      __typename
    }
  }
`;
